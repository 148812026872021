import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { navigate, useLocation } from '@reach/router';
import { parse } from 'qs';

import Card from '../../components/Card';
import claimDetailsSideMenuItems from '../Account/ClaimDetails/claimDetailsSideMenuItems';
import backIcon from '../../assets/icons/back-arrow-icon.inline.svg';
import messages from '../messages/searchFlightForm';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import {
  FLIGHT_COMPENSATION,
  CALCULATOR_TYPE,
  PRODUCT_TYPE,
} from '../../services/appLocalStorage';
import { useGlobalState } from '../../services/GlobalStore/GlobalStore';
import { removeCustomerFlights } from '../../services/GlobalStore/reducer';
import { addCustomerFlight } from '../../services/resources/customerFlights';
import Main from '../../components/Main';
import StepTitle from '../../components/StepTitle';
import { steps, translateRoute } from '../../utils/routes';
import routes from '../../messages/routes';
import FlightSearch from '../FlightSearch';
import Seo from '../../components/seo';
import InfoOverlay from '../../components/InfoOverlay';
import AccountContainer from '../../components/AccountContainer';
import Loading from '../../components/Loading';

const SearchFlightsFormItinerary = () => {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const location = useLocation();
  const queryObject = parse(search, { ignoreQueryPrefix: true });
  const [fixCounter, setFixCounter] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const fixedAirports =
    queryObject?.arrivalAirport && queryObject?.departureAirport
      ? [queryObject?.departureAirport, queryObject?.arrivalAirport]
      : [];

  const [, setCalculatorType] = useLocalStorageState(
    CALCULATOR_TYPE,
    FLIGHT_COMPENSATION
  );

  const [, setProductType] = useLocalStorageState(
    PRODUCT_TYPE,
    FLIGHT_COMPENSATION
  );

  const [customerFlights, dispatch] = useGlobalState('customerFlights.booked');
  const [, setFlightDisruptions] = useLocalStorageState(
    'flightDisruptions',
    {}
  );

  useEffect(() => {
    dispatch(removeCustomerFlights(0, 'booked'));
    dispatch(removeCustomerFlights(0, 'alternative'));
    setFlightDisruptions(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitFlight = () => {
    if (fixCounter === 1) {
      setLoading(true);
      setCalculatorType(FLIGHT_COMPENSATION);
      setProductType(FLIGHT_COMPENSATION);
      const {
        airline_code,
        flight_number,
        flight_date,
        type,
        airline_id,
        departure_airport_id,
        arrival_airport_id,
        claimId,
        claim_number,
      } = customerFlights[0];

      const flight_code = `${airline_code}${flight_number}`;
      const formatFlight = {
        flight_date,
        type: location?.state?.flight === 'connecting' ? type : 'alternative',
        flight_status:
          location?.state?.flight === 'connecting'
            ? 'missed_connection'
            : 'replacement',
        airline: { id: airline_id },
        flight_number,
        departureAirport: { id: departure_airport_id },
        arrivalAirport: { id: arrival_airport_id },
        flight_code,
      };

      addCustomerFlight({
        ...formatFlight,
        claim: { id: claimId },
      }).then(res => {
        if (res) {
          setLoading(false);
          navigate(
            `/${translateRoute(routes.account)}/${translateRoute(
              routes.claim
            )}/${claim_number}/${translateRoute(routes.flightItinerary)}`
          );
        }
      });
    }
    // tslint:disable-next-line:no-shadowed-variable
    setFixCounter(fixCounter => fixCounter + 1);
  };

  return (
    <AccountContainer
      menuItems={[
        {
          ...claimDetailsSideMenuItems(location?.state?.claimId)[3],
          title: messages.back,
          icon: backIcon,
        },
      ]}
    >
      <Main>
        {isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <Loading />
          </div>
        ) : (
          <Card onlyDesktop={true}>
            <Seo title={messages.homepageTitle} />
            <div className="flex justify-center sm:justify-between items-center">
              <StepTitle>
                {location?.state.flight === 'connecting'
                  ? formatMessage(messages.connectigFlightFormHeader)
                  : formatMessage(messages.replacementFlightFormHeader)}
              </StepTitle>
              <InfoOverlay
                title={messages.searchFlightsFormHeader}
                description={messages.asideText}
              />
            </div>

            <Card onlyMobile={true}>
              <FlightSearch
                step={steps.FlightCompensation}
                flightType={'booked'}
                // eslint-disable-next-line react/jsx-no-bind
                navigateNext={handleSubmitFlight}
                fixedAirports={fixedAirports}
                showAirportFields={true}
                isFlightItinerary={true}
              />
            </Card>
          </Card>
        )}
        <div id="delegateSubmit" />
      </Main>
    </AccountContainer>
  );
};

export default SearchFlightsFormItinerary;
