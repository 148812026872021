import React, { Fragment, useEffect } from 'react';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import AirportSelect, {
  airportSelectMessages,
} from '../../components/fields/AirportSelect';
import DepartureIcon from '../../assets/icons/departure-icon.inline.svg';
import StopoverIcon from '../../assets/icons/stop-icon.inline.svg';
import ArrivalIcon from '../../assets/icons/arrival-icon.inline.svg';
import IconButton from '../../components/IconButton';

// Classes to align button with input
const addButtonClasses = 'mt-30 sm:mt-42.5 h-25 ml-8 sm:ml-20 relative';

// eslint-disable-next-line react/no-multi-comp
const AirportFields = ({ values, setValues }) => {
  useEffect(() => {
    if (!values?.stopovers) {
      setValues({
        ...values,
        stopovers: [null],
      });
    }
  }, [values, setValues]);

  const { formatMessage } = useIntl();

  return (
    <div className="fieldset sm:dashed-line relative">
      <FieldArray
        name={'stopovers'}
        // eslint-disable-next-line react/jsx-no-bind
        render={({ remove, push }) => (
          <Fragment>
            <AirportSelect
              className="sm:with-bullet"
              name="departure_airport"
              label={airportSelectMessages.labelDepartureAirport}
              placeholder={airportSelectMessages.placeholderDepartureAirport}
              icon={<DepartureIcon />}
              autoComplete="off"
            />
            {values?.stopovers?.length > 0
              ? values.stopovers.map((stop, index) => (
                  <div
                    className="input-group relative flex items-center"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`stopover-${index}`}
                  >
                    <AirportSelect
                      className="sm:with-bullet sm:max-w-315"
                      name={`stopovers.${index}`}
                      label={formatMessage(
                        airportSelectMessages.labelStopoverAirport,
                        { number: index + 1 }
                      )}
                      placeholder={
                        airportSelectMessages.placeholderStopoverAirport
                      }
                      icon={<StopoverIcon />}
                      autoComplete="off"
                    />
                    {index === 0 ? (
                      <IconButton
                        type={'add'}
                        className={addButtonClasses}
                        // eslint-disable-next-line
                        onClick={() => push(null)}
                      />
                    ) : (
                      <IconButton
                        type={'remove'}
                        className={addButtonClasses}
                        // eslint-disable-next-line
                        onClick={() => remove(index)}
                      />
                    )}
                  </div>
                ))
              : null}
            <AirportSelect
              className="sm:with-bullet"
              name="arrival_airport"
              label={airportSelectMessages.labelArrivalAirport}
              placeholder={airportSelectMessages.placeholderArrivalAirport}
              icon={<ArrivalIcon />}
              autoComplete="off"
            />
            <p className="sm:hidden mt-25">
              <span data-before="+">
                {/* eslint-disable-next-line */}
                <span className="link" onClick={() => push('')}>
                  {formatMessage(airportSelectMessages.addStopover)}
                </span>
              </span>
            </p>
          </Fragment>
        )}
      />
    </div>
  );
};

AirportFields.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default AirportFields;
