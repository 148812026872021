/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Card from '../../../../components/Card';
import { useIntl } from 'react-intl';
import sideComponentIcon from '../../../../assets/images/account-page/sideComponentIcon.png';
import messages from '../newMessages';

const HelpCard = (props: any) => (
  <div
    className={'rounded px-30 py-25 text-sm w-full mb-15'}
    style={{ backgroundColor: '#F8F8F9' }}
    {...props}
  />
);

// eslint-disable-next-line react/no-multi-comp
const HelpSideComponent = () => {
  const { formatMessage } = useIntl();

  return (
    <Card
      className="mr-0 mt-10 px-2 sm:mr-20 sm:mt-0 sm:px-0"
      style={{ maxWidth: '450px' }}
    >
      <div className="flex flex-col items-center">
        <img src={sideComponentIcon} alt="" />
        <div className="flex flex-col items-center mt-10 mb-10">
          <span style={headerStyle}>
            {formatMessage(messages.helpSideBarTitle)}
          </span>
          <span style={subTextStyle}>
            {formatMessage(messages.helpSideBarSubTitle)}
          </span>
        </div>

        <HelpCard>
          <div>
            <span style={boxStyle}>1</span>
            <br />
            <br />
            <span style={subTextStyle}>
              {formatMessage(messages.helpSideBarFirstParagraph)}
            </span>
          </div>
        </HelpCard>

        <HelpCard>
          <div>
            <span style={boxStyle}>2</span>
            <br />
            <br />
            <span style={subTextStyle}>
              {formatMessage(messages.helpSideBarSecondParagraph)}
            </span>
          </div>
        </HelpCard>

        <HelpCard>
          <div>
            <span style={boxStyle}>3</span>
            <br />
            <br />
            <span style={subTextStyle}>
              {formatMessage(messages.helpSideBarThirdParagraph)}
            </span>
          </div>
        </HelpCard>
      </div>
    </Card>
  );
};

export default HelpSideComponent;

const subTextStyle = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '17.78px',
  color: '#0f0f0f',
};

const headerStyle = {
  fontWeight: 600,
  fontSize: '28px',
  lineHeight: '33.89px',
  letterSpacing: '-2%',
  color: '#101828',
};

const boxStyle = {
  border: '1px solid',
  padding: '8px 20px',
  borderRadius: '4px',
  backgroundColor: '#f36b13',
  color: 'white',
  fontWeight: 700,
};
