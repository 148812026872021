import React, { useEffect, useRef } from 'react';
import Avatar from '../../../../assets/images/account-page/avatar.png';
import YourceAvatar from '../../../../assets/images/account-page/yourceAvatar.png';
import ScrollIcon from '../../../../assets/images/account-page/scrollIcon.png';
import { useIntl } from 'react-intl';
import newMessages from '../newMessages';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import config from '../../../../config';

const DIVIDER_STYLE = {
  marginTop: '5%',
  marginBottom: '5%',
  borderBottom: '1px solid #d9dbe9',
};

const locale = {
  en: 'en-gb',
  nl: 'nl',
  es: 'es',
  pl: 'pl',
  be: 'fr',
  fr: 'fr',
  it: 'it',
  de: 'de',
  da: 'da',
};

/* tslint:disable no-var-requires */
require(`moment/locale/${locale[config?.locale] || 'en-gb'}`);

const Messages = (props: any) => {
  const { messages, setIsScrolled, isScrolled = false } = props;
  const { formatMessage } = useIntl();
  const div = useRef(null);
  const messageBody = document.getElementById('message-body');

  useEffect(() => {
    if (messageBody !== null) {
      messageBody.scrollTop =
        messageBody?.scrollHeight - messageBody?.clientHeight;
    }
  }, [messageBody]);

  const formatDate = (created: string) => {
    const dateFormat = new Date(created);
    const date = dateFormat.getDate();
    const month = moment(dateFormat).format('MMMM');
    const year = dateFormat.getFullYear();
    if (config?.locale.includes('en') || config?.locale.includes('de')) {
      const capitalizeMonth = month[0].toUpperCase() + month.slice(1);
      return `${date} ${capitalizeMonth} ${year}`;
    } else {
      return `${date} ${month} ${year}`;
    }
  };

  const closeMessagesArea = () => {
    if (!isScrolled && setIsScrolled) {
      setIsScrolled(true);
    }
  };

  const scrollToBottom = () => {
    const lastItem = [...document?.querySelectorAll('.message-list')]?.pop();
    setIsScrolled(true);
    lastItem.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <div
      className="mt-25"
      style={{
        padding: '0px 22px',
        height: isMobile && !isScrolled ? '200px' : 'auto',
        maxHeight: '600px',
        overflow: 'auto',
      }}
      ref={div}
      id="message-body"
      // eslint-disable-next-line react/jsx-no-bind
      onScroll={() => closeMessagesArea()}
    >
      {isMobile && messages?.length > 4 ? (
        <div
          style={{ position: 'fixed', right: 0, bottom: '20%' }}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={scrollToBottom}
        >
          <img src={ScrollIcon} alt="" />
        </div>
      ) : null}

      {messages?.reverse().map(message => (
        <div key={message?.id}>
          <div>
            {message?.direction === 'sent' ? (
              <div className="flex justify-between message-list">
                <div className="flex">
                  <img
                    src={Avatar}
                    alt=""
                    style={{ width: '40px', height: '40px' }}
                  />
                  <div className="flex flex-col ml-10">
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#344054',
                        lineHeight: '20px',
                      }}
                    >
                      You
                    </span>
                    <span
                      style={{
                        fontSize: '12px',
                        color: '#000000',
                        lineHeight: '20px',
                        fontWeight: 300,
                      }}
                    >
                      {formatMessage(newMessages.messageTopic)}:{' '}
                      {message?.category?.name || '-'}
                    </span>
                  </div>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: '12px',
                      color: '#636363',
                      lineHeight: '20px',
                      fontWeight: 300,
                    }}
                  >
                    {formatDate(message?.created_at)}
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex justify-between">
                <div className="flex">
                  <img
                    src={YourceAvatar}
                    alt=""
                    style={{ width: '40px', height: '40px' }}
                  />
                  <div className="flex flex-col ml-10">
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#344054',
                        lineHeight: '20px',
                      }}
                    >
                      {message?.author_name}
                    </span>
                    <span
                      style={{
                        fontSize: '12px',
                        color: '#000000',
                        lineHeight: '20px',
                        fontWeight: 300,
                      }}
                    >
                      {formatMessage(newMessages.messageAutoReply)}
                    </span>
                  </div>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: '12px',
                      color: '#636363',
                      lineHeight: '20px',
                      fontWeight: 300,
                    }}
                  >
                    {formatDate(message?.created_at)}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div style={{ padding: '20px 14px 10px 20px' }}>
            <p
              className="whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: message?.message }}
            />
          </div>
          <div style={DIVIDER_STYLE} />
        </div>
      ))}
    </div>
  );
};

export default Messages;
