import React, { useEffect } from 'react';
import { useJsonApi } from '../../../../services/GlobalStore/GlobalStore';
import { useIntl } from 'react-intl';
import messages from '../newMessages';

const dropdownStyle = {
  padding: '12px 16px',
  borderRadius: '8px',
  border: '1px solid #D0D5DD',
  outline: 'none',
};

const SelectTopic = ({
  claimId,
  setSelectedTopic,
  hasMessages,
  isMobile = false,
}: {
  claimId: string;
  setSelectedTopic: any;
  hasMessages: boolean;
  isMobile: boolean;
}) => {
  const { formatMessage } = useIntl();
  const {
    data: categories,
    loading: isLoadingCategories,
    get: getCategories,
  } = useJsonApi(`/supportMessageCategories`, {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  useEffect(() => {
    if (!isLoadingCategories) {
      getCategories(`?filter[claim.id]=${claimId}&include=children`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCategories, claimId]);

  const handleOnChange = e => {
    setSelectedTopic(e?.target?.value);
  };

  return (
    <div
      className="flex flex-col"
      style={
        !isMobile && !hasMessages
          ? { padding: '0px 32px', width: '385px', marginTop: '1.5625rem' }
          : hasMessages && !isMobile
          ? { width: '385px', marginTop: '0.5rem' }
          : {}
      }
    >
      {!isMobile && !hasMessages ? (
        <label
          htmlFor="topics"
          style={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            color: '#344054',
          }}
        >
          {formatMessage(messages.selectLabel)}
        </label>
      ) : null}
      <select
        id="label"
        style={dropdownStyle}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleOnChange}
      >
        <option value="" selected={true} disabled={true} hidden={true}>
          {formatMessage(messages.selectPlaceholder)}
        </option>
        {categories?.map(
          (category: {
            id: string | number;
            name: string;
            children: any[];
          }) => (
            <optgroup label={category?.name} key={category?.id}>
              {category?.children?.map(option => (
                <option value={option?.id} key={option?.id}>
                  {option?.name}
                </option>
              ))}
            </optgroup>
          )
        )}
      </select>
    </div>
  );
};

export default SelectTopic;
