import '../../utils/yupIntlSetup';
import * as yup from 'yup';

export const validateConnectionType = yup.object().shape({
  departure_airport: yup
    .string()
    .nullable()
    .required(),
  arrival_airport: yup
    .string()
    .nullable()
    .required(),
  stopovers: yup.array(
    yup
      .string()
      .nullable()
      .required()
  ),
});
