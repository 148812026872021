/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import MessageArea from './MessageArea';
import Messages from './Messages';
import { sendSupportMessage } from '../../../../services/resources/supportMessages';
import { useJsonApi } from '../../../../services/GlobalStore/GlobalStore';
import InfoIcon from '../../../../assets/images/account-page/infoIcon.png';
import BackIcon from '../../../../assets/icons/back-icon.inline.svg';
import ArrowRight from '../../../../assets/images/account-page/arrowRight.svg';
import Card from '../../../../components/Card';
import Loading from '../../../../components/Loading';
import newMessages from '../newMessages';
import { useIntl } from 'react-intl';
import config from '../../../../config';
import moment from 'moment';

const locale = {
  en: 'en-gb',
  nl: 'nl',
  es: 'es',
  pl: 'pl',
  be: 'fr',
  fr: 'fr',
  it: 'it',
  de: 'de',
  da: 'da',
};

/* tslint:disable no-var-requires */
require(`moment/locale/${locale[config?.locale] || 'en-gb'}`);

const MoblieMessaging = ({
  claim,
  setSelectedClaim,
}: {
  claim: any;
  setSelectedClaim: any;
}) => {
  const [selectedTopic, setSelectedTopic] = useState('');
  const [message, setMessage] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();

  const transformDate = (date: string) => {
    const dateFormat = new Date(date);
    const day = dateFormat.getDate();
    const month = moment(dateFormat).format('MMMM');
    const year = dateFormat.getFullYear();

    if (config?.locale.includes('en') || config?.locale.includes('de')) {
      const capitalizeMonth = month[0].toUpperCase() + month.slice(1);
      return `${day} ${capitalizeMonth} ${year}`;
    } else {
      return `${day} ${month} ${year}`;
    }
  };

  const {
    data: messages,
    loading: isLoadingmessages,
    get: loadMessages,
  } = useJsonApi(`/supportMessages`, {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  useEffect(() => {
    if (!isLoadingmessages) {
      loadMessages(`?filter[claim.id]=${claim?.id}&include=claim,category`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim?.id, loadMessages]);

  const sendMessage = () => {
    setIsLoading(true);
    sendSupportMessage(
      {
        message,
        type: 'supportMessages',
        claim: { id: claim?.id },
        category: { id: selectedTopic },
      },
      {}
    )
      .then(async () => {
        const loadedMessages = await loadMessages(
          `?filter[claim.id]=${claim?.id}&include=claim,category`
        );
        if (loadedMessages?.data?.length) {
          setMessage('');
          setIsLoading(false);
          const textArea = document.getElementById('message-textarea');
          if (textArea && textArea.value) {
            textArea.value = '';
          }
        }
      })
      .catch(() => {
        setMessage('');
        setIsLoading(false);
      });
  };

  const goBack = () => {
    setSelectedClaim({});
  };

  return (
    <div style={{ marginTop: '-3.1rem' }}>
      <Card
        style={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px' }}
      >
        <div
          className="flex items-center justify-between"
          style={{
            padding: '1rem',
            backgroundColor: '#F9FAFB',
          }}
        >
          <button onClick={goBack} style={headerStyle}>
            <BackIcon className="inline" />
          </button>
          <div className="flex items-center">
            <span style={subHeaderStyle}>
              {claim?.customerFlights?.length &&
                claim.flights?.[0]?.departureAirport?.iata}
            </span>
            <span>
              <img src={ArrowRight} alt="" />
            </span>
            <span style={subHeaderStyle}>
              {claim?.customerFlights?.length &&
                claim.flights?.[0]?.arrivalAirport?.iata}
            </span>
            <div className="info-tooltip">
              <img src={InfoIcon} alt="" style={{ marginLeft: '15px' }} />
              <div className="info-tooltiptext-mobile flex flex-col">
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    color: '#FFFFFF',
                  }}
                >
                  {formatMessage(newMessages.toolTipText)}
                </span>
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#FFFFFF',
                  }}
                >
                  Airline:{' '}
                  {claim?.customerFlights?.length &&
                    claim.customerFlights[0]?.airline?.name}
                </span>
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#FFFFFF',
                  }}
                >
                  {formatMessage(newMessages.messageSupportClaimNr)}:{' '}
                  {claim?.claim_number}
                </span>
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#FFFFFF',
                  }}
                >
                  {formatMessage(newMessages.toolTipTextFlight)}:{' '}
                  {claim?.customerFlights?.length &&
                    transformDate(claim?.customerFlights?.[0]?.flight_date)}
                </span>
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#FFFFFF',
                  }}
                >
                  {formatMessage(newMessages.toolTipTextClaim)}:{' '}
                  {claim?.customerFlights?.length &&
                    transformDate(claim?.created_at)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-25" style={{ padding: '0px 12px' }}>
          <span style={headerTextStyle}>
            {formatMessage(newMessages.helpFooterButtonTitle)}
          </span>{' '}
          <br />
          <span style={headerSubTextStyle}>
            {formatMessage(newMessages.messageSupportSubTitle)}
          </span>
          <div style={DIVIDER_STYLE} />
        </div>
        {messages?.length && !isLoading && !isLoadingmessages ? (
          <Messages
            messages={messages?.reverse()}
            isMobile={true}
            setIsScrolled={setIsScrolled}
            isScrolled={isScrolled}
          />
        ) : isLoading || isLoadingmessages ? (
          <Loading className="flex justify-center my-30" />
        ) : null}
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <MessageArea
            setMessage={setMessage}
            claimId={claim?.id}
            claimNumber={claim?.claim_number}
            setSelectedTopic={setSelectedTopic}
            selectedTopic={selectedTopic}
            hasMessages={!!messages?.length}
            isScrolled={isScrolled}
            setIsScrolled={setIsScrolled}
            compensationPerPassenger={claim?.compensation_per_passenger}
            claimCurrentState={claim?.currentState}
          />
          <div
            className="flex justify-end mt-5"
            style={isScrolled ? { display: 'none' } : {}}
          >
            <button style={buttonStyle} onClick={sendMessage}>
              {formatMessage(newMessages.messageSendButton)}
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MoblieMessaging;

const headerStyle = {
  width: '44px',
  height: '44px',
  backgroundColor: 'white',
};

const headerTextStyle = {
  fontSize: '16px',
  lineHeight: '19px',
  color: '#101828',
  fontWeight: 600,
  letterSpacing: '-2%',
};

const subHeaderStyle = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#2C2C2C',
};

const headerSubTextStyle = {
  fontSize: '12px',
  lineHeight: '14px',
  color: '#667085',
  fontWeight: 400,
};
const DIVIDER_STYLE = {
  marginTop: '5%',
  borderBottom: '1px solid #d9dbe9',
};

const buttonStyle = {
  width: '159px',
  padding: '8px 4px',
  backgroundColor: '#F36B13',
  color: '#FFFFFF',
  border: '1px solid #F36B13',
  borderRadius: '8px',
  boxShadow: '0px 1px 2px 0px #1018280D',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
};
