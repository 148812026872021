/* eslint-disable react/no-multi-comp */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

// Components
import InputGroupWrapper from './InputGroup';

const RadioOption = props => {
  const { option, tagClassName, onChange, index, layout, className } = props;
  const { formatMessage } = useIntl();

  // For mobile toggle we must use Fragment
  const Tag = layout !== 'horizontal' ? 'div' : Fragment;

  return (
    <Tag key={option.value} {...tagClassName}>
      <input
        id={`input-radio-${option.value}`}
        type="radio"
        name={option.value}
        checked={props.field.value === option.value}
        className={clsx(
          'form-radio',
          {
            'ml-30': layout === 'horizontal' && index > 0,
            'absolute ml-10 sm:relative sm:ml-0': layout === 'vertical',
          },
          className
        )}
        aria-invalid={props.hasError}
        {...props.field}
        onChange={onChange}
        value={option.value}
      />
      {option.label && (
        <label
          className={clsx({
            'ml-8': layout === 'horizontal',
            'shadow-tile rounded-md py-13 pl-40 leading-normal pr-20 w-full sm:shadow-none sm:rounded-0 sm:p-0 sm:w-auto sm:ml-15':
              layout === 'vertical',
          })}
          htmlFor={`input-radio-${option.value}`}
        >
          {props.field.value === option.value ? (
            <b>{formatMessage(option.label)}</b>
          ) : (
            formatMessage(option.label)
          )}
        </label>
      )}
    </Tag>
  );
};

RadioOption.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object,
  hasError: PropTypes.bool,
  index: PropTypes.number,
  layout: PropTypes.oneOf(['vertical', 'horizontal', 'bordered', 'tiles']),
  onChange: PropTypes.func,
  option: PropTypes.object,
  tagClassName: PropTypes.object,
};

const Radio = ({
  className,
  options,
  layout,
  onChangeCallback,
  seperator,
  ...props
}) => {
  const { formatMessage } = useIntl();

  let tagClassName = {
    ...(typeof Tag === 'string'
      ? {
          className: clsx('', {
            'flex items-center mb-22 relative sm:mb-15': layout === 'vertical',
          }),
        }
      : {}),
  };

  function onChange(e) {
    onChangeCallback && onChangeCallback(e.target.value);
    props?.field.onChange(e);
  }

  return (
    <div
      className={clsx('my-30 flex justify-center sm:justify-start', {
        'flex-col': layout === 'vertical',
        'items-center': layout === 'horizontal',
      })}
    >
      {seperator ? (
        <Fragment>
          <RadioOption
            option={options[0]}
            index={0}
            key={`input-radio-${options[0].value}`}
            id={`input-radio-${options[0].value}`}
            onChange={onChange}
            className={className}
            tagClassName={tagClassName}
            layout={layout}
            {...props}
          />
          <span className="text-gray-300 ml-30">
            {seperator?.id ? formatMessage(seperator) : seperator}
          </span>
          <RadioOption
            option={options[1]}
            index={1}
            key={`input-radio-${options[1].value}`}
            id={`input-radio-${options[1].value}`}
            onChange={onChange}
            className={className}
            tagClassName={tagClassName}
            layout={layout}
            {...props}
          />
        </Fragment>
      ) : (
        options?.map((option, index) => (
          <RadioOption
            option={option}
            index={index}
            key={`input-radio-${option.value}`}
            id={`input-radio-${option.value}`}
            onChange={onChange}
            className={className}
            tagClassName={tagClassName}
            layout={layout}
            {...props}
          />
        ))
      )}
    </div>
  );
};

Radio.defaultProps = {
  layout: 'horizontal',
};

Radio.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  groupLabel: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  layout: PropTypes.oneOf(['vertical', 'horizontal', 'bordered', 'tiles']),
  name: PropTypes.string,
  nameField: PropTypes.string,
  onChangeCallback: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  seperator: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
};

// eslint-disable-next-line react/no-multi-comp
const RadioField = props => <InputGroupWrapper {...props} component={Radio} />;

export default RadioField;
