import React from 'react';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';

import Container from './Container';
import Main from './Main';
import SideMenu from '../views/Account/SideMenu';
import { MenuItem } from '../views/Account/SideMenu/SideMenuItem';
import HelpSideComponent from '../views/Account/Support/HelpSideComponent';
import ClaimsSideComponent from '../views/Account/Support/HelpSideComponent/ClaimsSideComponent';
import translation from '../translations/main.json';
interface AccountContainerProps {
  menuItems?: MenuItem[];
  children: any;
  hasSideBarComponent?: boolean;
  claims?: any[];
  setSelectedClaim?: any;
  selectedClaim?: any;
}

const AccountContainer = ({
  menuItems,
  children,
  hasSideBarComponent,
  claims,
  setSelectedClaim,
  selectedClaim,
}: AccountContainerProps) => {
  // Checking if is support page because we need to remove menu items and
  // add new side component and also change layout styles

  const isHelpPage =
    typeof window !== 'undefined' &&
    (window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1
    ) === translation['cc4.seo.route.help-center'] ||
      window.location.pathname.substring(
        window.location.pathname.lastIndexOf('/') + 1
      ) === translation['cc4.account-page.help.message-contact-us-route'] ||
      window.location.pathname
        .split('/')
        .includes(translation['cc4.seo.route.help-center']));

  return (
    <>
      {!isHelpPage ? (
        <Container>
          {menuItems ? <SideMenu items={menuItems} /> : null}
          <Main
            className={clsx({
              container: isMobile,
              'mx-auto': !menuItems?.length,
            })}
          >
            {children}
          </Main>
        </Container>
      ) : (
        <Container>
          <div
            className="flex flex-col-reverse md:flex-row w-full"
            style={
              isMobile && !selectedClaim?.id
                ? { paddingRight: '0.5rem', paddingLeft: '0.5rem' }
                : {}
            }
          >
            {hasSideBarComponent ? <HelpSideComponent /> : null}
            {claims?.length ? (
              <ClaimsSideComponent
                claims={claims}
                setSelectedClaim={setSelectedClaim}
                selectedClaim={selectedClaim}
              />
            ) : null}
            <Main
              className={clsx({
                container: isMobile,
                'mx-auto': !menuItems?.length,
              })}
            >
              {children}
            </Main>
          </div>
        </Container>
      )}
    </>
  );
};

export default AccountContainer;
