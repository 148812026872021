import React from 'react';
import SelectTopic from './SelectTopic';
import RecomendedAnswer from './RecomendedAnswer';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import messages from '../newMessages';

const MessageArea = (props: any) => {
  const {
    setMessage,
    claimId = null,
    claimNumber = null,
    setSelectedTopic,
    hasMessages = false,
    isScrolled = false,
    setIsScrolled,
    selectedTopic = '',
    compensationPerPassenger,
    claimCurrentState = {},
  } = props;
  const { formatMessage } = useIntl();

  const handleOnChange = e => {
    setMessage(e?.target.value);
  };

  const handleSetIsScrolled = () => {
    if (setIsScrolled) {
      setIsScrolled(false);
    }
  };

  return (
    <div
      className="mt-25"
      style={
        !isMobile
          ? { padding: '0 32px' }
          : isScrolled
          ? { height: '125px' }
          : { height: 'auto' }
      }
    >
      <div style={isMobile ? borderStyleMobile : borderStyle}>
        {isMobile || hasMessages ? (
          <>
            <SelectTopic
              claimId={claimId}
              setSelectedTopic={setSelectedTopic}
              hasMessages={hasMessages}
              isMobile={isMobile}
            />
            {selectedTopic ? (
              <RecomendedAnswer
                selectedTopic={selectedTopic}
                claimNumber={claimNumber}
                compensationPerPassenger={compensationPerPassenger}
                claimCurrentState={claimCurrentState}
              />
            ) : null}
          </>
        ) : null}
      </div>
      <textarea
        id="message-textarea"
        className="w-full"
        style={{ ...textAreaStyle, ...(isScrolled ? {} : { height: '180px' }) }}
        placeholder={formatMessage(messages.messageAreaLabel)}
        // eslint-disable-next-line react/jsx-no-bind
        onFocus={() => setIsScrolled(false)}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleOnChange}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleSetIsScrolled}
      />
    </div>
  );
};

export default MessageArea;

const borderStyle = {
  padding: '20px 12px',
  backgroundColor: '#F9FAFB',
  borderRadius: '12px 12px 0px 0px',
  border: '1px solid #E9E9E9',
};

const borderStyleMobile = {
  padding: '20px 12px',
  backgroundColor: '#F9FAFB',
};

const textAreaStyle = {
  border: '1px solid #E9E9E9',
  borderRadius: '0px 0px 12px 12px ',
  borderTop: 'none',
  padding: '10px 14px 6px 10px',
  outlineColor: 'black',
};
