import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import SideMenuItem, { MenuItem } from './SideMenuItem';

const SideMenu = ({ items }: { items: MenuItem[] }) => {
  const { state } = useLocation();

  return (
    <aside className="mb-30 lg:mr-10 lg:mb-0 lg:flex-shrink-0 lg:w-aside px-10 lg:px-0 bg-white lg:bg-transparent whitespace-nowrap overflow-x-scroll lg:overflow-x-hidden border-b border-t border-gray-700 lg:border-none">
      <div className="flex lg:flex-col align-center">
        {items.map((item, index) => {
          return item.hidden ? null : (
            <SideMenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              icon={item.icon}
              title={item.title}
              state={state}
              to={item.to}
              basepath={item.basepath}
            />
          );
        })}
      </div>
    </aside>
  );
};

SideMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

export default SideMenu;
