import { defineMessages } from 'react-intl';

export default defineMessages({
  claimSideMenuOverviewTitle: {
    id: 'cc4.claim.side-menu.overview',
    defaultMessage: 'Overview',
  },
  claimSideMenuBookingDetails: {
    id: 'cc4.claim.side-menu.booking-details',
    defaultMessage: 'Booking details',
  },
  claimSideMenuDocumentsTitle: {
    id: 'cc4.claim.side-menu.documents',
    defaultMessage: 'Documents',
  },
  claimDetailsClaimStatusTitle: {
    id: 'cc4.claim.card.claim-status-title',
    defaultMessage: 'Claim status',
  },
  uploadNowButton: {
    id: 'cc4.claim.card.alert.claim-upload-now-button',
    defaultMessage: 'Upload now',
  },
  alertMessage: {
    id: 'cc4.claim.card.alert.alert-message',
    defaultMessage:
      '<cta>Requirements missing!</cta> Complete your dossier to get compensated',
  },
  readMoreButton: {
    id: 'cc4.claim.card.read-more',
    defaultMessage: 'Read more',
  },
  claimTitleInHeader: {
    id: 'cc4.claim.card.header.claim-title-in-header',
    defaultMessage: '{flightStatus} - Claim #{claimNumber}',
  },
  claimStatusTitle: {
    id: 'cc4.claim.card.claim-status-title',
    defaultMessage: 'Claim status',
  },
  passengerTitle: {
    id: 'cc4.claim.card.passenger-title',
    defaultMessage: 'Passenger',
  },
  editButtonTitle: {
    id: 'cc4.claim.card.edit-button-title',
    defaultMessage: 'Edit',
  },
  moreButtonTitle: {
    id: 'cc4.claim.card.show-button-title',
    defaultMessage: 'More',
  },
  sideMenuItemBack: {
    id: 'cc4.side-menu.back',
    defaultMessage: 'Back',
  },
  flightItinerary: {
    id: 'cc4.claim.card.flight-itinerary-title',
    defaultMessage: 'Flight itinerary',
  },
});
