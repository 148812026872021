import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { useLocation } from '@reach/router';
import { parse } from 'qs';
import { isEmpty } from 'lodash';

import AirportFields from './AirportFields';
import Card from '../../components/Card';
import Radio from '../../components/fields/Radio';
import messages from '../messages/searchFlightForm';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import {
  FLIGHT_COMPENSATION,
  CALCULATOR_TYPE,
  PRODUCT_TYPE,
} from '../../services/appLocalStorage';
import FormikPersist from '../../components/fields/FormikPersist';
import { validateConnectionType } from './validate';
import { useGlobalState } from '../../services/GlobalStore/GlobalStore';
import { removeCustomerFlights } from '../../services/GlobalStore/reducer';
import Icon from '../../components/Icon';
import bulbIcon from '../../assets/icons/bulb.svg';
import Main from '../../components/Main';
import Aside from '../../components/Aside';
import Container from '../../components/Container';
import StepTitle from '../../components/StepTitle';
import { steps, useTranslatedNavigate } from '../../utils/routes';
import routes from '../../messages/routes';
import StepNavigation from '../../components/StepNavigation';
import FlightSearch from '../FlightSearch';
import Seo from '../../components/seo';
import InfoOverlay from '../../components/InfoOverlay';
import TrustBox from '../../components/TrustBox';
import SocialProof from '../../components/SocialProof';
import SocialProofMobile from '../../components/SocialProof/Mobile';
import SearchFlightsFormItinerary from './SearchFlightsFormItinerary';

const SearchFlightsForm = () => {
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();
  const { search } = useLocation();
  const location = useLocation();

  const isFlightItinerary = location?.pathname?.includes('flight-itinerary');
  const queryObject = parse(search, { ignoreQueryPrefix: true });
  const initialValues =
    queryObject?.flightNumber && queryObject?.flightDate
      ? {
          flight_code: queryObject?.flightNumber,
          departure_date: queryObject?.flightDate,
        }
      : {};
  const fixedAirports =
    queryObject?.arrivalAirport && queryObject?.departureAirport
      ? [queryObject?.departureAirport, queryObject?.arrivalAirport]
      : [];

  const [, setCalculatorType] = useLocalStorageState(
    CALCULATOR_TYPE,
    FLIGHT_COMPENSATION
  );

  const [, setProductType] = useLocalStorageState(
    PRODUCT_TYPE,
    FLIGHT_COMPENSATION
  );

  const [, dispatch] = useGlobalState('customerFlights.booked');
  const [, setFlightDisruptions] = useLocalStorageState(
    'flightDisruptions',
    {}
  );

  useEffect(() => {
    dispatch(removeCustomerFlights(0, 'booked'));
    dispatch(removeCustomerFlights(0, 'alternative'));
    setFlightDisruptions(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [connectionType, setConnectionType] = useState(
    !isEmpty(initialValues) ? 'direct' : ''
  );
  const [connectionHasSelected, setConnectionHasSelected] = useState(false);
  const changeConnectionType = (value: string) => {
    setConnectionType(value);
    setConnectionHasSelected(true);
  };

  useEffect(() => {
    const localConnectionType = localStorage.getItem('yo:flight-type-form');
    if (JSON.parse(localConnectionType)?.value?.connectionType) {
      setConnectionType(JSON.parse(localConnectionType)?.value?.connectionType);
      setConnectionHasSelected(true);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setConnectionHasSelected(true);
    }
  }, [initialValues]);

  const handleSubmit = useCallback(() => {
    setCalculatorType(FLIGHT_COMPENSATION);
    setProductType(FLIGHT_COMPENSATION);
    if (connectionType === 'connecting') {
      translatedNavigate('flights/1');
    } else {
      translatedNavigate(routes.itinerary, { replace: true });
    }
  }, [connectionType, setCalculatorType, setProductType, translatedNavigate]);

  return (
    <>
      {!isFlightItinerary ? (
        <Container>
          <Main>
            <Card onlyDesktop={true}>
              <Seo title={messages.homepageTitle} />
              <div className="flex justify-center sm:justify-between items-center">
                <StepTitle>
                  {formatMessage(messages.searchFlightsFormHeader)}
                </StepTitle>
                <InfoOverlay
                  title={messages.searchFlightsFormHeader}
                  description={messages.asideText}
                />
              </div>
              <p className="mb-35 mt-0 mx-10 sm:mx-0 text-black text-center sm:text-left">
                {formatMessage(messages.searchFlightsFormDescription)}
              </p>
              <Formik
                initialValues={{
                  connectionType: !isEmpty(initialValues) ? 'direct' : '',
                }}
                // initialErrors are due to bug #1950 reported on formik github
                initialErrors={
                  !isEmpty(initialValues) ? {} : { connectionType: '' }
                }
                isInitialValid={!isEmpty(initialValues) ? true : false}
                onSubmit={handleSubmit}
                validationSchema={validateConnectionType}
                validateOnMount={!isEmpty(initialValues) ? true : false}
              >
                {({ values, setValues }) => (
                  <Form>
                    <Radio
                      className="sm:max-w-none max-w-none"
                      name="connectionType"
                      // eslint-disable-next-line
                      onChangeCallback={changeConnectionType}
                      options={[
                        {
                          label: messages.connectionTypeLabelDirect,
                          value: 'direct',
                        },
                        {
                          label: messages.connectionTypeLabelConnecting,
                          value: 'connecting',
                        },
                      ]}
                      seperator={formatMessage(messages.or)}
                    />
                    {connectionType === 'connecting' && (
                      <Card onlyMobile={true}>
                        <AirportFields setValues={setValues} values={values} />
                        <StepNavigation
                          dataLayerPushValueOnSubmit={'connectionType'}
                          submitButtonMessage={formatMessage(messages.continue)}
                          step={steps.FlightCompensation}
                          portalId="delegateSubmit"
                        />
                      </Card>
                    )}
                    <FormikPersist localStorageKey="flight-type-form" />
                  </Form>
                )}
              </Formik>
              {(!connectionType || connectionType === 'direct') && (
                <Card onlyMobile={true}>
                  <FlightSearch
                    step={steps.FlightCompensation}
                    flightType={'booked'}
                    navigateNext={handleSubmit}
                    fixedAirports={fixedAirports}
                    showAirportFields={true}
                    connectionHasSelected={connectionHasSelected}
                    isFirstPage={true}
                    initialValues={initialValues}
                  />
                </Card>
              )}
            </Card>
            <div id="delegateSubmit" />
          </Main>
          <Aside className={'whitespace-pre-line'} showSocialProof={true}>
            <SocialProof>
              <Icon src={bulbIcon} className="mb-20" />
              {formatMessage(messages.asideText)}
            </SocialProof>
          </Aside>
          <TrustBox />
          <SocialProofMobile />
        </Container>
      ) : (
        <SearchFlightsFormItinerary />
      )}
    </>
  );
};

export default SearchFlightsForm;
