import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../newMessages';
import DownArrow from '../../../../assets/images/account-page/recommended.png';
import { navigate } from '@reach/router';
import translations from '../../../../translations/main.json';
import { isEmpty } from 'lodash';

const textStyle = {
  fontSize: '14px',
  fontWeight: 400,
  color: '#667085',
};

const RecomendedAnswer = (props: {
  selectedTopic: string;
  claimNumber: string | number;
  compensationPerPassenger: any;
  withBackground: boolean;
  claimCurrentState: { title: string; description: string };
}) => {
  const {
    selectedTopic,
    claimNumber,
    compensationPerPassenger,
    withBackground = false,
    claimCurrentState,
  } = props;
  const { formatMessage } = useIntl();

  const getAnswer = () => {
    const asnwer = recommendedAnswers.find(answ => answ?.id === selectedTopic);
    if (selectedTopic === '2') {
      return (
        <div>
          <span
            dangerouslySetInnerHTML={{ __html: claimCurrentState?.title }}
          />
          <span
            dangerouslySetInnerHTML={{ __html: claimCurrentState?.description }}
          />
        </div>
      );
    } else if (selectedTopic === '16' || selectedTopic === '23') {
      return formatMessage(messages[asnwer?.value], {
        br: <br />,
      });
    } else if (
      selectedTopic === '19' ||
      selectedTopic === '20' ||
      selectedTopic === '21'
    ) {
      return formatMessage(messages[asnwer?.value], {
        link: (
          // eslint-disable-next-line react/jsx-no-bind
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => moveTo(getCorrectLink())}
          >
            {getCorrectLink()}
          </span>
        ),
        br: <br />,
      });
    } else if (selectedTopic === '24') {
      return formatMessage(messages[asnwer?.value], {
        claim_current_claimamount_pp: (
          <span>
            {compensationPerPassenger?.amount}{' '}
            {compensationPerPassenger?.currency}{' '}
          </span>
        ),
      });
    }
    if (messages[asnwer?.value]) {
      return formatMessage(messages[asnwer?.value]);
    }
    return '';
  };

  const hasAnswer = () => {
    const asnwer = recommendedAnswers.find(answ => answ?.id === selectedTopic);
    if (!isEmpty(asnwer) && !isEmpty(messages[asnwer?.value])) {
      return true;
    }
    return false;
  };

  const getCorrectLink = () => {
    if (selectedTopic === '19') {
      return `${translations['cc4.seo.route.shareable-claim-page']}/${claimNumber}/${translations['cc4.seo.route.documents']}`;
    } else if (selectedTopic === '20') {
      return `${translations['cc4.seo.route.shareable-claim-page']}/${claimNumber}/${translations['cc4.seo.route.upload-other-documents']}`;
    } else {
      return `${translations['cc4.seo.route.shareable-claim-page']}/${claimNumber}/${translations['cc4.seo.route.claims-flight-itinerary']}`;
    }
  };

  const moveTo = (url: string) => {
    navigate(url);
  };

  return (
    <>
      {hasAnswer() ? (
        <div
          style={{ marginTop: '5px', padding: withBackground ? '0 32px' : 0 }}
        >
          <div
            style={
              withBackground
                ? {
                    backgroundColor: '#F8F8F9',
                    padding: '12px',
                    borderRadius: '8px',
                  }
                : {}
            }
          >
            <span style={textStyle}>
              {formatMessage(messages.recomendedAnswersLabel)}
            </span>
            <div className="flex">
              <img
                src={DownArrow}
                alt=""
                style={{ width: '15px', height: '15px' }}
              />
              <span>{getAnswer()}</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RecomendedAnswer;

const recommendedAnswers = [
  {
    id: '2',
    value: 'updateClaim',
  },
  {
    id: '15',
    value: 'receivedCompensationAnswer',
  },
  {
    id: '16',
    value: 'cancelClaimAnswer',
  },
  {
    id: '17',
    value: 'airlineResponse',
  },
  {
    id: '19',
    value: 'uploadDocumentsAnswer',
  },
  {
    id: '20',
    value: 'additionalCosts',
  },
  {
    id: '21',
    value: 'flightInformation',
  },
  {
    id: '23',
    value: 'receiveCompensationQuestion',
  },
  {
    id: '24',
    value: 'compensationAmount',
  },
  {
    id: '32',
    value: 'complaintAnswer',
  },
  {
    id: '33',
    value: 'otherAnswer',
  },
];
