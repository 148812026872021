import { jsonApiAxios } from '../api';

const relationshipToTypeMap = {
  category: 'supportMessageCategories',
};

export const supportMessagesSchema = {
  attributes: ['message', 'claim', 'category', 'supportMessageCategories'],
  claim: {
    ref: 'id',
  },
  category: {
    ref: 'id',
  },
  typeForAttribute: attribute => relationshipToTypeMap[attribute],
};

/**
 * Service for sending the support message to backend
 * @param {*} values
 * @param {*} config
 * @returns
 */

export function sendSupportMessage(values, config = {}) {
  return jsonApiAxios.post('/supportMessages', values, {
    schema: supportMessagesSchema,
    type: 'supportMessages',
    ...config,
  });
}
