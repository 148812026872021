import { defineMessages } from 'react-intl';

export default defineMessages({
  helpMainTitle: {
    id: 'cc4.account-page.help.title',
    defaultMessage: 'How can I help you today ?',
  },
  helpMainTitleTop: {
    id: 'cc4.account-page.help.top-title',
    defaultMessage: "Hi, I'm Claudia",
  },
  resultHelpLabel: {
    id: 'cc4.account-page.help.result-help-label',
    defaultMessage: 'This what I found on',
  },
  searchPlaceholder: {
    id: 'cc4.account-page.help.search-placeholder',
    defaultMessage: 'Search your question',
  },
  searchSubText: {
    id: 'cc4.account-page.help.search-sub-text',
    defaultMessage: 'Get an answer in less than 30 seconds',
  },
  commonQuestionsTitle: {
    id: 'cc4.account-page.help.common-questions-title',
    defaultMessage: 'Common questions from passengers like you',
  },
  helpFooterTitle: {
    id: 'cc4.account-page.help.footer-text',
    defaultMessage: 'Not what you are looking for?',
  },
  helpFooterSubTitle: {
    id: 'cc4.account-page.help.footer-sub-text',
    defaultMessage: 'Contact our Claim Specialists!',
  },
  helpFooterButtonTitle: {
    id: 'cc4.account-page.help.footer-button-text',
    defaultMessage: 'Contact us',
  },
  resultsPlaceholder: {
    id: 'cc4.account-page.help.results-placeholder',
    defaultMessage: 'Best answers',
  },
  helpFooterConfirmationButtonTitle: {
    id: 'cc4.account-page.help.footer-confirmation-text',
    defaultMessage: 'Does this answer your question?',
  },
  helpFooterFeedbackText: {
    id: 'cc4.account-page.help.footer-feedback-text',
    defaultMessage: 'Thank you for your feedback.',
  },
  helpFooterButtonYes: {
    id: 'cc4.account-page.help.footer-button-yes',
    defaultMessage: 'Yes',
  },
  helpFooterButtonNo: {
    id: 'cc4.account-page.help.footer-button-no',
    defaultMessage: 'No',
  },
  helpFooterRedirectText: {
    id: 'cc4.account-page.help.footer-redirect-text',
    defaultMessage:
      'We will redirect you to our contact form in {counter} seconds.',
  },
  helpSideBarTitle: {
    id: 'cc4.account-page.help.sidebar-title',
    defaultMessage: 'Get your answers faster!',
  },
  helpSideBarSubTitle: {
    id: 'cc4.account-page.help.sidebar-sub-title',
    defaultMessage: "We're here to help.",
  },
  helpSideBarFirstParagraph: {
    id: 'cc4.account-page.help.sidebar-first-paragraph',
    defaultMessage:
      'Ask Claudia your question and let her find an answer for you in letss than 30 seconds!',
  },
  helpSideBarSecondParagraph: {
    id: 'cc4.account-page.help.sidebar-second-paragraph',
    defaultMessage: 'Review the information provided by Claudia.',
  },
  helpSideBarThirdParagraph: {
    id: 'cc4.account-page.help.sidebar-third-paragraph',
    defaultMessage:
      'If you have a more complex question, you can contact our Claim Specialists.',
  },
  helpSideBarClaimTitle: {
    id: 'cc4.account-page.help.sidebar-claim-title',
    defaultMessage: 'Your Claims',
  },
  helpSidebarOpenTab: {
    id: 'cc4.account-page.help.sidebar-open-tab-text',
    defaultMessage: 'Open claims',
  },
  helpSidebarClosedTab: {
    id: 'cc4.account-page.help.sidebar-close-tab-text',
    defaultMessage: 'Closed claims',
  },
  statusLabel: {
    id: 'cc4.account-page.help.sidebar-status-label',
    defaultMessage: 'Status',
  },
  passengerCountLabel: {
    id: 'cc4.account-page.help.sidebar-mobile-passenger-count',
    defaultMessage: 'With {count} passengers...',
  },
  newMessageLabel: {
    id: 'cc4.account-page.help.sidebar-message-label',
    defaultMessage: 'new message',
  },
  noClosedClaimsMsg: {
    id: 'cc4.account-page.help.sidebar-no-closed-claims-msg',
    defaultMessage: 'No closed claims have been found!',
  },
  noOpenClaimsMsg: {
    id: 'cc4.account-page.help.sidebar-no-open-claims-msg',
    defaultMessage: 'No open claims have been found!',
  },
  messageSupportTitle: {
    id: 'cc4.account-page.help.message-title',
    defaultMessage: 'Your support on',
  },
  messageSupportSubTitle: {
    id: 'cc4.account-page.help.message-sub-title',
    defaultMessage:
      'Depending on the complexity of your question, it might take us up to a few days to get back to you.',
  },
  messageSupportClaimNr: {
    id: 'cc4.account-page.help.message-claim-nr-label',
    defaultMessage: 'Claim nr',
  },
  messageSendButton: {
    id: 'cc4.account-page.help.message-button-text',
    defaultMessage: 'Send',
  },
  toolTipText: {
    id: 'cc4.account-page.help.message-tooltip-text',
    defaultMessage: 'About your flight',
  },
  toolTipTextFlight: {
    id: 'cc4.account-page.help.message-tooltip-flight-label',
    defaultMessage: 'Flight Date',
  },
  toolTipTextClaim: {
    id: 'cc4.account-page.help.message-tooltip-claim-label',
    defaultMessage: 'Claim Date',
  },
  noAnswersFound: {
    id: 'cc4.account-page.help.message-no-answer-label',
    defaultMessage: 'No answers found',
  },
  cardIconLabel: {
    id: 'cc4.account-page.help.message-card-icon-label',
    defaultMessage: "Let's get you connected",
  },
  cardTitle: {
    id: 'cc4.account-page.help.message-card-title',
    defaultMessage: 'Select your claim first',
  },
  cardText: {
    id: 'cc4.account-page.help.message-card-text',
    defaultMessage:
      'It looks like you have multiple claims with us. Please pick the one you want to contact us about.',
  },
  messageTopic: {
    id: 'cc4.account-page.help.message-topic-label',
    defaultMessage: 'Topic',
  },
  messageAutoReply: {
    id: 'cc4.account-page.help.message-auto-reply-label',
    defaultMessage: 'This is an auto-reply',
  },
  selectPlaceholder: {
    id: 'cc4.account-page.help.message-select-placeholder',
    defaultMessage: 'Select topic',
  },
  selectLabel: {
    id: 'cc4.account-page.help.message-select-label',
    defaultMessage: 'What is the topic of your question',
  },
  selectOptionLabel: {
    id: 'cc4.account-page.help.message-select-option-label',
    defaultMessage: 'Reply to message',
  },
  recomendedAnswersLabel: {
    id: 'cc4.account-page.help.message-select-answers-label',
    defaultMessage: 'Recommended answers',
  },
  updateClaim: {
    id: 'cc4.account-page.help.message-update-claim-label',
    defaultMessage: 'Your claim is currently in the {staging} stage.',
  },
  receivedCompensationAnswer: {
    id: 'cc4.account-page.help.message-answer-received-compensation-label',
    defaultMessage:
      'Great news! Please let us know the details by sending a message using the form below so that we can make sure you are receiving the full amount to which you are entitled.',
  },
  cancelClaimAnswer: {
    id: 'cc4.account-page.help.message-answer-cancel-claim-label',
    defaultMessage:
      'You can cancel your claim free of charge within 14 days from submitting your claim.* If you wish to cancel your claim after this time, a cancellation fee might be applicable due to the work already performed by our Team in regards to your claim.{br} Do you want to proceed with cancellation? Then please send us a message regarding this using the form below.{br} *Please keep in mind that we start work on your claim right after you submit it. If the claim gets assigned by the airline within the initial 14 days, we consider it a completed claim and therefore a cancellation fee might be applicable should you wish to proceed with cancellation nonetheless.',
  },
  additionalCosts: {
    id: 'cc4.account-page.help.message-answer-additional-costs-label',
    defaultMessage:
      'Please add your receipts/invoices here: {link} {br}Did you encounter any issues with our website? Then please let us know the details using the form below.',
  },
  complaintAnswer: {
    id: 'cc4.account-page.help.message-answer-complaint-label',
    defaultMessage:
      'We are sorry to hear that! Please let us know the details by sending a message using the form below. We will get back to you as soon as possible.',
  },
  otherAnswer: {
    id: 'cc4.account-page.help.message-answer-other-label',
    defaultMessage:
      'For faster processing of your question, please check if one of the other topics apply to your claim. If this is not the case, please let us know the details of your request by sending a message using the form below.',
  },
  uploadDocumentsAnswer: {
    id: 'cc4.account-page.help.message-upload-documents-label',
    defaultMessage:
      'You can add or sign documents in the Documents section of your account: {link}.{br} Did you encounter any issues with our website? Then please let us know the details using the form below.',
  },
  airlineResponse: {
    id: 'cc4.account-page.help.message-airline-response-label',
    defaultMessage:
      "Please share with us the details of the airline's response using the form below. This information may be crucial for us in the next steps of the process.",
  },
  flightInformation: {
    id: 'cc4.account-page.help.message-flight-information-label',
    defaultMessage:
      'You can provide additional information regarding your flight in the Flight Itinerary section of your account: {link}.{br} Did you encounter any issues with our website? Then please let us know the details using the form below.',
  },
  receiveCompensationQuestion: {
    id: 'cc4.account-page.help.message-receive-compensation-question-label',
    defaultMessage:
      'We are currently awaiting payment from the airline. This may take up to a few weeks. In some cases, we might need to initiate a debt collection against the airline — then, the waiting period might be, unfortunately, longer.{br} Our Team is trying its best to get your money back as quickly as possible. As soon as we have any news, we will let you know.',
  },
  compensationAmount: {
    id: 'cc4.account-page.help.message-receive-compensation-amount-label',
    defaultMessage:
      'We expect that you will receive {claim_current_claimamount_pp} for each passenger included in your claim.',
  },
  bankDetails: {
    id: 'cc4.account-page.help.message-bank-details-label',
    defaultMessage:
      "We're sorry to hear that! Please explain your issue using the form below. Please keep in mind that an International Bank Account Number (IBAN) is required for us to send you your money. Are you not a citizen of the European Union or the United Kingdom? Then you might not have an IBAN. In this case, please let us know in which country your bank is located and we will get back to you.",
  },
  incorrectAmount: {
    id: 'cc4.account-page.help.message-incorrect-amount-label',
    defaultMessage:
      "We're sorry to hear that! Please contact us using the form below to clarify the situation. We will get back to you as soon as possible.",
  },
  allAnswersRoute: {
    id: 'cc4.account-page.help.message-all-answers-route',
    defaultMessage: 'all-answers',
  },
  messageAreaLabel: {
    id: 'cc4.account-page.help.message-area-label',
    defaultMessage: 'Type your message here.',
  },
});
