/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Card from '../../../../components/Card';
import ArrowRight from '../../../../assets/images/account-page/arrowRight.svg';
import UserIcon from '../../../../assets/images/account-page/userIcon.svg';
import MessageIcon from '../../../../assets/images/account-page/message.png';
import MoblieMessaging from '../HelpSuppotMessaging/MoblieMessaging';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import messages from '../newMessages';
import { useIntl } from 'react-intl';
import moment from 'moment';
import config from '../../../../config';

const locale = {
  en: 'en-gb',
  nl: 'nl',
  es: 'es',
  pl: 'pl',
  be: 'fr',
  fr: 'fr',
  it: 'it',
  de: 'de',
  da: 'da',
};

/* tslint:disable no-var-requires */
require(`moment/locale/${locale[config?.locale] || 'en-gb'}`);

const closedStates = [
  'Deleted',
  'FlightValidatedNegative',
  'StopProcedureNotEntitledRegulation',
  'StopProcedureNotEntitledExtraordinary',
  'StopProcedureNoFurtherOptions',
  'StopProcedureCustomerDemand',
  'InvoicePaymentReceived',
  'InvoiceNODPaymentReceived',
  'InvoicePaymentReceivedAfterDebtCollection',
  'ClosedWithoutInvoice',
  'FinancePaidOutFromYourceToCustomer',
];

// eslint-disable-next-line react/no-multi-comp
const ClaimsSideComponent = (props: any) => {
  const { claims, setSelectedClaim, selectedClaim } = props;
  const [selectedClaimStatus, setSelectedClaimStatus] = useState<string>(
    'open'
  );
  const [selectedClaimIndex, setSelectedClaimIndex] = useState<number>();
  const [claimsByState, setClaimsByState] = useState<any>([]);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (selectedClaimStatus === 'closed') {
      setClaimsByState(
        claims.filter((claim: { currentState: { value: string } }) =>
          closedStates?.includes(claim?.currentState?.value)
        )
      );
    } else {
      setClaimsByState(
        claims.filter(
          (claim: { currentState: { value: string } }) =>
            !closedStates?.includes(claim?.currentState?.value)
        )
      );
    }
  }, [claims, selectedClaimStatus]);

  const transformDate = (date: string) => {
    const dateFormat = new Date(date);
    const day = dateFormat.getDate();
    const month = moment(dateFormat).format('MMMM');
    const year = dateFormat.getFullYear();

    if (config?.locale.includes('en') || config?.locale.includes('de')) {
      const capitalizeMonth = month[0].toUpperCase() + month.slice(1);
      return `${day} ${capitalizeMonth} ${year}`;
    } else {
      return `${day} ${month} ${year}`;
    }
  };

  const setClaim = (claim: any, index: number) => {
    setSelectedClaim(claim);
    setSelectedClaimIndex(index);
  };

  return (
    <>
      {(isMobile && isEmpty(selectedClaim)) || !isMobile ? (
        <Card
          style={
            !isMobile
              ? {
                  width: '450px',
                  marginRight: '3%',
                  maxHeight: '500px',
                  overflow: 'auto',
                }
              : { width: 'auto' }
          }
        >
          <div className="flex flex-col items-start">
            <div className="flex flex-col items-start mt-10 mb-10">
              <span style={headerStyle}>
                {formatMessage(messages.helpSideBarClaimTitle)}
              </span>
              {isMobile ? (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#667085',
                  }}
                >
                  Pick the claim you want to contact us about.
                </span>
              ) : null}
            </div>
            <div className="sm:flex button-group">
              <button
                className="button-claim"
                style={{
                  backgroundColor:
                    selectedClaimStatus === 'open' ? '#F8F8F9' : 'white',
                  outlineColor: 'black',
                }}
                onClick={() => setSelectedClaimStatus('open')}
              >
                {formatMessage(messages.helpSidebarOpenTab)}
              </button>
              <button
                className="button-claim"
                style={{
                  backgroundColor:
                    selectedClaimStatus === 'closed' ? '#F8F8F9' : 'white',
                  outlineColor: 'black',
                }}
                onClick={() => setSelectedClaimStatus('closed')}
              >
                {formatMessage(messages.helpSidebarClosedTab)}
              </button>
            </div>
            <div
              style={
                isMobile
                  ? {
                      width: '100%',
                      marginTop: '5%',
                      padding: '20px',
                      borderRadius: '12px',
                    }
                  : { width: '100%', marginTop: '5%' }
              }
            >
              {claimsByState?.length ? (
                claimsByState?.map((claim: any, index: number) => (
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  <div
                    key={claim?.id}
                    className="cursor-pointer p-13"
                    style={
                      selectedClaimIndex === index
                        ? {
                            backgroundColor: '#F8F8F9',
                            border: '2px solid #269C74',
                            borderRadius: '12px',
                          }
                        : isMobile
                        ? {
                            backgroundColor: '#F8F8F9',
                            marginBottom: '10px',
                            borderRadius: '12px',
                          }
                        : {}
                    }
                    onClick={() => setClaim(claim, index)}
                  >
                    <div className="flex justify-between">
                      <div
                        className="flex items-center"
                        style={{
                          color: claim?.unread_support_count
                            ? '#F36B13'
                            : '#2C2C2C',
                        }}
                      >
                        <span style={subHeaderStyle}>
                          {claim?.flights?.[0]?.arrivalAirport?.iata}
                        </span>
                        <span>
                          <img src={ArrowRight} alt="" />
                        </span>
                        <span style={subHeaderStyle}>
                          {claim.flights?.[0]?.departureAirport?.iata}
                        </span>
                      </div>
                      <div>
                        <span>Claim nr: {claim?.claim_number}</span>
                      </div>
                    </div>
                    <div className="mt-20">
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '17.78px',
                          color: '#0F0F0F',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: claim?.currentState?.title,
                        }}
                      />
                      <br />
                      {isMobile ? (
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '17.78px',
                            color: '#606060',
                          }}
                        >
                          {' '}
                          Your claim from{' '}
                          {claim.flights?.[0]?.arrivalAirport?.name} to{' '}
                          {claim.flights?.[0]?.departureAirport?.name} on{' '}
                          {transformDate(
                            claim?.customerFlights?.[0]?.flight_date
                          )}
                          .{' '}
                          {formatMessage(messages.passengerCountLabel, {
                            count: <span>{claim?.passengers?.length}</span>,
                          })}
                        </span>
                      ) : (
                        <div className="flex">
                          <span>
                            {claim?.customerFlights?.[0]?.airline?.name} |{' '}
                          </span>
                          <span style={{ marginLeft: '1%' }}>
                            {claim?.customerFlights?.[0]?.flight_code} |{' '}
                          </span>
                          <img
                            src={UserIcon}
                            alt=""
                            style={{ marginLeft: '1%' }}
                          />
                          <span style={{ marginLeft: '1%' }}>
                            {claim?.passengers?.length}{' '}
                          </span>
                        </div>
                      )}
                      <div className="flex items-center">
                        <img
                          src={MessageIcon}
                          alt=""
                          style={{ marginRight: '1%' }}
                        />
                        <span style={subHeaderStyle}>
                          {claim?.unread_support_count}{' '}
                          {formatMessage(messages.newMessageLabel)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ marginTop: '25%' }}>
                  <span style={textStyle}>
                    {selectedClaimStatus === 'open'
                      ? formatMessage(messages.noOpenClaimsMsg)
                      : formatMessage(messages.noClosedClaimsMsg)}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Card>
      ) : (
        <MoblieMessaging
          claim={selectedClaim}
          setSelectedClaim={setSelectedClaim}
        />
      )}
    </>
  );
};

export default ClaimsSideComponent;

const headerStyle = {
  fontWeight: 600,
  fontSize: '28px',
  lineHeight: '33.89px',
  letterSpacing: '-2%',
  color: '#101828',
};

const subHeaderStyle = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
};

const textStyle = {
  fontSize: '20px',
  lineHeight: '30px',
  color: '#667085',
  fontWeight: 400,
};
